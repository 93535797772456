'use client';

import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Matrix } from 'components/new-components/Matrix/Matrix';
import HomeHightLightCarousel from 'components/new-components/HighLightCarousel/HightLightCarousel';

import { MATRIX_TYPE } from 'constants/index';
import { GetServerSideHomePropsReturnType } from 'services/home/fetchHome';
import homeStore from 'stores/HomeStore';
import authStore from 'stores/AuthStore';
import locationStore from 'stores/LocationStore';

import * as styles from './Content.css';

type Props = GetServerSideHomePropsReturnType & {
  isAuthenticated: boolean;
};

function HomeContentTemplate({
  isAuthenticated,
  hydrationData: { homeHydration, locationHydration },
}: Props) {
  homeStore.hydrate(homeHydration);

  const HightLightComponent = homeStore.homeResult?.components?.find(
    (c) => c.response_type === 'sponsored',
  );

  useEffect(() => {
    homeStore.getScreenData({
      afterComponentsCount: 4,
    }).then((response) => {
      const fetchedComponents = response.components || [];
      const currentComponents = homeStore.homeResult?.components || [];

      const newComponents = fetchedComponents.filter(
        (c) => !currentComponents.some((existing) => existing.uuid === c.uuid),
      );

      const merged = [...currentComponents, ...newComponents];

      homeStore.setHomeResult({
        ...homeStore.homeResult!,
        components: merged,
      });
    });
  }, []);

  useEffect(() => {
    if (!(isAuthenticated === authStore.isAuthenticated) || locationStore.selectedLocation.city !== locationHydration.selectedLocation.city) {
      homeStore.getScreenData().then((data) => {
        homeStore.setHomeResult({
          ...homeStore.homeResult!,
          components: data.components,
        });
      });
    }
  }, [authStore.isAuthenticated, locationStore.selectedLocation.city]);

  return (
    <>
      {HightLightComponent && (
        <HomeHightLightCarousel
          component={HightLightComponent}
          prefixTrackingName={MATRIX_TYPE.MATRIX}
          matrizTemplate={MATRIX_TYPE.HOME}
        />
      )}

      <div className={styles.wrapper}>
        <Matrix
          components={homeStore.homeResult?.components || []}
          matrixType={homeHydration.homeResult?.template}
          matrixUuid={homeHydration.homeResult?.uuid}
          prefixTrackingName={MATRIX_TYPE.MATRIX}
          cardPreviousView={homeHydration.cardPreviousView || null}
          userLocation={locationHydration.selectedLocation || {}}
        />
      </div>
    </>
  );
}

export default observer(HomeContentTemplate);
