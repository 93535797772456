import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Autoplay, EffectCoverflow, Navigation, Pagination,
} from 'swiper/modules';
import { CLASS_TRACKING_GTM } from 'constants/index';
import { HightLightResponse } from 'matrix';
import Link from 'next/link';
import { createPropsToTrackingMatrix } from 'components/Integration/createPropsToTrackingMatrix';
import * as styles from './Carousel.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

interface CarouselProps {
  id: string;
  slides: HightLightResponse[];
  handleChangeSlide: (index: number) => void;
  componentTemplate: string;
  prefixTrackingName: string;
  matrizTemplate: string;
  templateType: string;
}

function Carousel({
  slides, id, handleChangeSlide, componentTemplate, prefixTrackingName, matrizTemplate, templateType,
}: CarouselProps) {
  return (
    <Swiper
      className={styles.customHightLightSwiper}
      modules={[Autoplay, Pagination, EffectCoverflow, Navigation]}
      id={id}
      slidesPerView={3}
      centeredSlides
      navigation={slides.length > 0}
      grabCursor
      longSwipes={false}
      shortSwipes={false}
      resistanceRatio={0}
      speed={500}
      effect="coverflow"
      pagination={{ type: 'bullets', clickable: true }}
      autoplay={{ disableOnInteraction: false }}
      slidesPerGroup={1}
      spaceBetween={-663}
      coverflowEffect={{
        rotate: 0,
        stretch: 100,
        depth: 200,
        modifier: 2,
        slideShadows: true,
      }}
      breakpoints={{
        1500: {
          spaceBetween: -500,
          slidesPerView: 3,
          speed: 500,
          touchRatio: 0.8,
        },
        1100: {
          touchRatio: 0.8,
        },
        768: {
          touchRatio: 0.3,
          slidesPerView: 2,
          spaceBetween: -500,
        },
        520: {
          speed: 700,
          touchRatio: 0.2,
          slidesPerView: 2,
          spaceBetween: -400,
        },
        420: {
          touchRatio: 0.2,
        },
        320: {
          speed: 700,
          touchRatio: 0.25,
          slidesPerView: 2,
          spaceBetween: -300,
        },
      }}
      onSwiper={(swiper) => handleChangeSlide(swiper.realIndex)}
      onSlideChange={(swiper) => handleChangeSlide(swiper.realIndex)}
    >
      {slides?.map((slide, index) => {
        const { dataCreative, sectionPosition } = createPropsToTrackingMatrix(index, {
          trackingPrefix: prefixTrackingName,
          matrizTemplate,
          componentTemplate,
          cardType: templateType,
          templateType,
          payloadCard: slide,
          dataLocation: null,
        });
        return (
          <SwiperSlide
            key={slide.event_id}
            className={`${CLASS_TRACKING_GTM.EVENT_CARD} ${styles.highLightSlide}`}
            data-bannerid={slide.event_id}
            data-name={slide.title}
            data-creative={dataCreative}
            data-position={`${sectionPosition || ''}`}
          >
            <Link href={slide.link_page}>
              <div className={styles.imageSlide} style={{ backgroundImage: `url(${slide.image_url})` }} />
            </Link>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default Carousel;
