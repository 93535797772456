/* eslint-disable @typescript-eslint/ban-ts-comment */

'use client';

import { useEffect, useState } from 'react';
import { HightLightResponse, MatrixComponent } from 'matrix';
import { useSearchParams } from 'next/navigation';
import searchCategoryType from 'services/searchCategoryType/searchClient';
import { DateFormatter, IconLocation } from '@sympla/sympla-components';
import IconFlatCalendar from 'assets/icons/ic-flat-calendar.svg';
import { HightLightWrapperSkeleton } from '../Matrix/components/CarouselWrapper/CarouselWrapper.skeleton';
import * as styles from './HightLightCarousel.css';
import Carousel from './Carousel/Carousel';

interface HomeHightLightCarouselProps {
  component: MatrixComponent;
  matrizTemplate: string;
  prefixTrackingName: string;
}

function HomeHightLightCarousel({ component, matrizTemplate, prefixTrackingName }: HomeHightLightCarouselProps) {
  const searchParams = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  // @ts-ignore
  const [highLightComponents, setHighLightComponents] = useState<HightLightResponse[] | []>(component.response?.data || []);
  const [componentActive, setComponentActive] = useState<HightLightResponse>();

  useEffect(() => {
    if (component && !component?.response) {
      const { service, service_params } = component;
      const d = searchParams?.get('d') as string | undefined;
      const p = searchParams?.get('p') as string | undefined;

      setIsLoading(true);
      searchCategoryType({
        ...service_params,
        d,
        p,
        service,
      })
        .then((response) => {
          if (response) {
            setHighLightComponents(response.data);
            setComponentActive(response.data[0]);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [component]);

  function handleChangeSlide(index: number | undefined) {
    if (index !== undefined) {
      setComponentActive(highLightComponents[index]);
    }
  }

  if (isLoading) {
    return <HightLightWrapperSkeleton />;
  }

  return (
    <div className={styles.container}>
      <Carousel
        slides={highLightComponents}
        id="hight-light-home"
        handleChangeSlide={(idx) => handleChangeSlide(idx)}
        componentTemplate={component.uuid}
        prefixTrackingName={prefixTrackingName}
        matrizTemplate={matrizTemplate}
        templateType={component.response_type}
      />
      {componentActive && (
        <div className={styles.infoContainer}>
          <h3 className={styles.infoTitle}>{componentActive.title}</h3>
          {componentActive.location && componentActive.start_date_formats && componentActive.end_date_formats && (
            <div className={styles.infoExtraContainer}>
              <span className={styles.infoExtraText}>
                <IconLocation />
                {componentActive.location.city}
                {' - '}
                {componentActive.location.state}
              </span>
              <span className={`${styles.infoExtraText} stroke-svg`}>
                <IconFlatCalendar />
                <DateFormatter
                  variant="neutral"
                  startDate={componentActive.start_date_formats.pt}
                  endDate={componentActive.end_date_formats.pt}
                  rawStartDate={componentActive.startDate}
                  rawEndDate={componentActive.endDate}
                />
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default HomeHightLightCarousel;
